body {
 font-family: 'Work Sans', sans-serif;
 padding: 10px;
 
 @media screen and (max-width: 800px) {
  padding: 0 5px;
  width: 100%
 }
}

h1, h2, h3, h4, h5, h6 {
 font-family: 'Crimson Text', serif;
}

a {
  text-decoration: none;
  color: black;
}
