.footer {
	display: flex;
	justify-content: center;
	flex-wrap: wrap-reverse;

	& .info {
		width: 500px;
	}

	& .social-network {
		width: 150px;
		padding: 0 15px;

		& .icons {
			display: flex;
			

			& .icon {
				width: 30px;
				height: 30px;
				margin: 5px;
			}
		}
	}

	& .contact {
		width: 250px;
		height: auto;
	}
}