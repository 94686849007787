.services-page {
	
	
	& h1 {
		display: flex;
		justify-content: center;
		text-align: center;
	}

	&.services-display {
		height: auto;
	}
}