.plans-preview {
	display: flex;
	flex-direction: row;
	font-size: 1.1em;

	@media screen and (max-width: 800px) {
		font-size: 0.9em;	
	}

	
	& .row {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		margin: 10px;

		& .column {
			display: flex;
			flex-direction: column;
			flex-basis: 100%;
			flex: 1;
			align-items: center;
			width: 30vw;

			@media screen and (max-width: 800px) {
				min-width: 320px;
			}


			& .plans-preview-image {
				width: 90%;
				min-width: 200px;
				padding-top: 50%;				
				background-position: center;
				background-size: cover;
			}
		
			& .plan {
				display: flex;
				flex-direction: column;
				flex-basis: 100%;
				flex: 1;
				align-items: center;

				& .details {
					display: flex;
					flex-direction: row;
					flex-basis: 100%;
					align-items: center;
					margin-bottom: 20px;

					& h3 {
						padding: 0;
					}
					
					& .description-details {
						padding: 0 10px;

						& div {
							padding: 5px;
						}
					}
				}			
			}
		}	
	}
}