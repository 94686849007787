.services-details {
	display: flex;
	flex-direction: column;
	font-size: 1.25em;
	justify-content: space-between;
	height: auto;

	& .row {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		height: auto;
		margin: 10px;
		border-top: 2px solid black;
		
		& .column {
			display: flex;
			flex-direction: column;
			flex-basis: 100%;
			flex: 1;
			margin: 0 10px;

			& h3 {
				min-width: 180px;

				@media screen and (max-width: 800px) {
					margin: 10px;
					font-size: 0.9em;

				}
			}
			
			& .image-title {
				display: flex;
				flex-direction: column;
				flex: 1;
				align-items: center;


				& .image {
					width: 90%;
					min-width: 200px;
					padding-top: 50%;
					background-size: cover;
				}
			}

			&.activities {
				
			}

			&.description {

			}
		}
	}
}