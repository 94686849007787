.custom-button {
	min-width: 165px;
	width: auto;
	height: 50px;
	letter-spacing: 0.5px;
	line-height: 50px;
	padding: 0 35px;
	font-size: 15px;
	background-color: green;
	color: white;
	text-transform: uppercase;	
	font-weight: bolder;
	border: none;
	cursor: pointer;

	&:hover{
		background-color: white;
		color: green;
		border: 1px solid green;
	}
}