.contact-info {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	height: auto;
	justify-content: space-around;
	align-items: center;
	text-align: center;

	@media screen and (max-width: 800px) {
		
	}

}