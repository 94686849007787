.header {
	width: 90%;
	display: flex;
	justify-content: space-around;
	align-content: center;
	flex-wrap: wrap;
	margin-bottom: 5px;
	font-size: 18px;

	@media screen and (max-width: 800px) {
 		width: 100%;
 		justify-content: space-between;
 		margin-bottom: 0;
 	}

	& .logo {
		height: 100px;
		padding: 10px;

		@media screen and (max-width: 800px) {
 			height: 40px;
 		}
	}

	& .menu {
		width: 50%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 20px;

		@media screen and (max-width: 800px) {
 			width: 60%;
 			padding: 8px;
		}

		& .option {
			padding: 20px 15px;
			color: #2882E1;
			font-size: 1.2em;

			@media screen and (max-width: 800px) {
 				padding: 15px 5px;
 				font-size: 0.85em;
 			}

			&:hover {
				text-decoration: underline #A0C332;
				font-weight: bold;
			}			
		}

		& p {
			padding: 20px 15px;
		}
	}
}



