.services {
	width: 100%;

	& h1 {
		display: flex;
		justify-content: center;
		text-align: center;

		@media screen and (max-width: 800px) {
			margin: 5px 0; 
		}
	}

	& .serviceslist {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
	}
}

