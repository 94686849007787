.slide-container {
  width: 80vw;
  margin: auto;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0;
  }

  & div {
    &.indicators {
      @media screen and (max-width: 800px) {
        margin-top: 0px;
      }
    }
  }
  
  & .each-slide{
    & div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 35vh;
    }

    & span {
      display: none;
      padding: 20px;
      font-size: 20px;
      background: #efefef;
      text-align: center;
    }
  }  
}
