.card {
	min-width: 400px;
	height: 300px;
	margin: 0 7.5px 15px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	overflow: hidden;

	@media screen and (max-width: 1430px) {
		min-width: 300px;
		height: 200px;
	}

	@media screen and (max-width: 1150px) {
		min-width: 400px;
		height: 300px;
	}

	&:hover {
		cursor: pointer;

		& .image {
			transform: scale(1.1);
			transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
		}

		& .card-detail {
			opacity: 0.9;
			max-width: 350px;

			& p {
				display: block;
			}
		}
	}

	.image {
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;

	}

	.card-detail {
	min-width: 150px;
	border: 1px solid black;
	border-radius: 10px;
	background-color: white;
	opacity: 0.5;
	position: absolute;
	
	
		h2 {
		margin: 5px;
		}

		p {
		margin: 5px;
		padding: 0;
		display: none;
		}
	}
}

