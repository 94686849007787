.contact-form {
	display: flex;
	flex-direction: column;
	min-width: 25vw;
	margin: 50px;

	@media screen and (max-width: 800px) {
		width: 50vw;
		margin: 20px;
	}
}